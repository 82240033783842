function objectifController(DatatableLang, $state, $scope, WS, $window, $compile, $sce) {
    'ngInject';
    const vm = this;
    vm.objectifs = {};
    vm.multi_objs = {};
    vm.month_selected = [];
    vm.auth_chargement = "1";
    vm.chargement_apply_filter = { chargement: vm.auth_chargement };
    vm.applyfilter = function () {
        if (vm.auth_chargement == "0") {
            vm.chargement_apply_filter = '';
        } else {
            vm.chargement_apply_filter = { chargement: vm.auth_chargement };
        }
    };

    vm.objectif_annuel = {};
    vm.objectif_annuel_prec = {};
    vm.mois = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'];

    vm.semestre_1 = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin'];
    vm.semestre_2 = ['juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'];

    vm.trimestre_1 = ['janvier', 'fevrier', 'mars'];
    vm.trimestre_2 = ['avril', 'mai', 'juin'];
    vm.trimestre_3 = ['juillet', 'aout', 'septembre'];
    vm.trimestre_4 = ['octobre', 'novembre', 'decembre'];

    vm.all_trims = [
        'objectif',
        'trimestre_1', 'trimestre_2', 'trimestre_3', 'trimestre_4', 'semestre_1', 'semestre_2',
        'total', 'valeur',
        'c_trimestre_1', 'c_trimestre_2', 'c_trimestre_3', 'c_trimestre_4', 'c_semestre_1', 'c_semestre_2',
        'r_trimestre_1', 'r_trimestre_2', 'r_trimestre_3', 'r_trimestre_4', 'r_semestre_1', 'r_semestre_2',
        'a_date',
        'c_date'
    ]
    //vm.multi_objs.users = [];
    vm.currentYear = moment().format("YYYY");
    vm.year = moment().format("YYYY");
    vm.find_by_month = moment().format("YYYY-MM");
    vm.multi_objs.month = moment().format("YYYY-MM");



    vm.month_calculate = {};
    // vm.month_calculate['tout'] = false;
    // _.each(vm.mois, element => {
    //     vm.month_calculate[element] = false; 
    // })

    vm.initRecalculate = () => {
        vm.month_selected = [];
        vm.month_calculate['tout'] = false;
        _.each(vm.mois, element => {
            vm.month_calculate[element] = false;
            var loader = 'trait_' + element;
            vm[loader] = '';
        })
    }
    vm.getMonthCalculate = () => {
        vm.month_selected = [];
        if (vm.month_calculate['tout'] == true) {
            _.each(vm.mois, element => {
                vm.month_calculate[element] = true;
                vm.month_selected.push(element);

                var loader = 'trait_' + element;
                vm[loader] = '';
            })
        } else {
            _.each(vm.mois, element => {
                vm.month_calculate[element] = false;

                var loader = 'trait_' + element;
                vm[loader] = '';
            })
        }

        console.log(vm.month_calculate, vm.month_selected)
    }

    vm.manageMonthCalculate = () => {
        vm.month_selected = [];
        _.each(vm.mois, element => {
            if (vm.month_calculate[element] == true) {
                vm.month_selected.push(element);
            }
            var loader = 'trait_' + element;
            vm[loader] = '';
        })
        console.log(vm.month_calculate, vm.month_selected)
    }
    vm.month_converted = {};
    _.each(vm.mois, (month, index) => {
        vm.month_converted[month] = index + 1;
    })

    vm.storeRecalculate = () => {
        if (vm.month_selected.length > 0) {
            _.each(vm.month_selected, month => {
                //vm.loader
                var data = {
                    year: vm.year,
                    monthNumber: vm.month_converted[month],
                    monthName: month,
                    region_code: vm.region_code,
                    user_code: vm.user_code,
                    type: vm.type_table
                }

                var loader = 'trait_' + month;
                vm[loader] = 'traitement';
                WS.post('objectifs/storeRecalculate', data)
                    .then(function (response) {
                        if (response.data.success == true) {
                            vm[loader] = 'success';
                        } else if (response.data.success == false) {
                            swal('Error', response.data.message, 'error');
                        }
                        $scope.$apply();
                    })
                    .then(null, function (error) {
                        console.log(error);
                        vm[loader] = 'error';
                        $scope.$apply();
                    });
            })

        } else {
            _.each(vm.mois, month => {
                var loader = 'trait_' + month;
                vm[loader] = '';
            })
        }
    }

    vm.getObjectifAnnuel = () => {
        vm.show_loader = true;
        WS.getData('objectifs/getObjectifAnnuel', { year: vm.year, region_code: vm.region_code })
            .then(function (response) {
                if (response.status == 200) {
                    //vm.objectif_annuel_prec = {};
                    console.log(response.data);

                    /**
                     * clear current annee
                     * 
                     */
                    _.each(vm.objectif_annuel, element => {
                        _.each(vm.mois, month => {
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel[element.produit_code][month] = "";
                            }

                            var c_month = 'c_' + month;
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel[element.produit_code][c_month] = "";
                            }

                            var r_month = 'r_' + month;
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel[element.produit_code][r_month] = "";
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel[element.produit_code][trim] = "";;
                            }
                        })

                    })

                    /**
                     * clear older year
                     */
                    _.each(vm.objectif_annuel_prec, element => {
                        // vm.objectif_annuel_prec[element.produit_code] = {};
                        _.each(vm.mois, month => {
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][month] = "";
                            }
                            var c_month = 'c_' + month;
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][c_month] = "";
                            }
                            var r_month = 'r_' + month;
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][r_month] = "";
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][trim] = "";
                            }
                        })


                    })


                    /**
                     * clear current year region
                     */
                    _.each(vm.objectif_annuel, element => {
                        //vm.objectif_annuel[element.produit_code] = {};
                        _.each(vm.mois, month => {
                            var month = month + '_coef';
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel[element.produit_code][month] = "";
                            }
                            var c_month = 'c_' + month + '_coef';
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel[element.produit_code][c_month] = "";
                            }
                            var r_month = 'r_' + month + '_coef';
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel[element.produit_code][r_month] = "";
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            var trim = trim + '_coef';
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel[element.produit_code][trim] = "";
                            }
                        })
                        vm.objectif_annuel[element.produit_code].coef = "";
                        vm.objectif_annuel[element.produit_code].objectif_coef_2 = "";

                    })
                    /**
                     * clear older year region
                     */
                    _.each(vm.objectif_annuel_prec, element => {
                        //vm.objectif_annuel_prec[element.produit_code] = {};
                        _.each(vm.mois, month => {
                            var month = month + '_coef';
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][month] = "";
                            }
                            var c_month = 'c_' + month + '_coef';
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][c_month] = "";
                            }
                            var r_month = 'r_' + month + '_coef';
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][r_month] = "";
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            var trim = trim + '_coef';
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][trim] = "";
                            }
                        })
                        vm.objectif_annuel_prec[element.produit_code].coef = "";
                        vm.objectif_annuel_prec[element.produit_code].objectif_coef_2 = "";

                    })

                    /**
                     * current annee
                     */
                    _.each(response.data.obj_annuel, element => {
                        _.each(vm.mois, month => {
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel[element.produit_code][month] = element[month] ? Number(element[month]) : "";
                            }
                            var c_month = 'c_' + month;
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel[element.produit_code][c_month] = element[c_month] ? Number(element[c_month]) : "";
                            }
                            var r_month = 'r_' + month;
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel[element.produit_code][r_month] = element[r_month] ? Number(element[r_month]) : "";
                                //console.log()
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel[element.produit_code][trim] = element[trim] ? Number(element[trim]) : "";;
                            }
                        })

                    })



                    /**
                     * annee precedant
                     */
                    _.each(response.data.obj_annuel_prec, element => {
                        //vm.objectif_annuel_prec[element.produit_code] = {};
                        _.each(vm.mois, month => {
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][month] = Number(element[month]);
                            }
                            var c_month = 'c_' + month;
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][c_month] = Number(element[c_month]);
                            }
                            var r_month = 'r_' + month;
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][r_month] = Number(element[r_month]);
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][trim] = Number(element[trim]);
                            }
                        })

                    })


                    /**
                     * current year region
                     */
                    _.each(response.data.obj_annuel_region, element => {
                        _.each(vm.mois, month => {
                            var month_coef = month + '_coef';
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel[element.produit_code][month_coef] = element[month] ? Number(element[month]) : "";
                            }

                            var c_month = 'c_' + month + '_coef';
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel[element.produit_code][c_month] = element[c_month] ? Number(element[c_month]) : "";
                            }
                            var r_month = 'r_' + month + '_coef';
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel[element.produit_code][r_month] = element[r_month] ? Number(element[r_month]) : "";
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            var trim_coef = trim + '_coef';
                            vm.objectif_annuel[element.produit_code][trim_coef] = element[trim] ? Number(element[trim]) : 0;
                            //console.log('trims_coef => ', trim, element[trim], vm.objectif_annuel[element.produit_code][trim_coef])

                        })

                        vm.objectif_annuel[element.produit_code].coef = element.coef ? Number(element.coef) : "";;
                        vm.objectif_annuel[element.produit_code].objectif_coef_2 = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;


                    })

                    _.each(response.data.obj_annuel_region_prec, element => {
                        //vm.objectif_annuel_prec[element.produit_code] = {};
                        _.each(vm.mois, month => {
                            var month_coef = month + '_coef';
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][month_coef] = Number(element[month]);
                            }
                            var c_month = 'c_' + month + '_coef';
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][c_month] = Number(element[c_month]);
                            }
                            var r_month = 'r_' + month + '_coef';
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][r_month] = Number(element[r_month]);
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            var trim_coef = trim + '_coef';
                            vm.objectif_annuel_prec[element.produit_code][trim_coef] = element[trim] ? Number(element[trim]) : 0;
                        })
                        vm.objectif_annuel_prec[element.produit_code].coef = element.coef ? Number(element.coef) : "";;
                        vm.objectif_annuel_prec[element.produit_code].objectif_coef_2 = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;

                    })
                    vm.show_loader = false;
                    $scope.$apply();
                } else { }

            })
            .then(null, function (error) {
                vm.show_loader = false;
                swal('Error', 'erreur de chargement des données', 'error')
                console.log(error);
            });
    }


    vm.getObjectifAnnuelUser = () => {
        vm.show_loader = true;
        WS.getData('objectifs/getObjectifAnnuelUser', { year: vm.year, region_code: vm.region_code, user_code: vm.user_code })
            .then(function (response) {
                if (response.status == 200) {
                    //vm.objectif_annuel_prec = {};
                    console.log(response.data);

                    /**
                     * clear current annee
                     * 
                     */
                    _.each(vm.objectif_annuel, element => {
                        _.each(vm.mois, month => {
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel[element.produit_code][month] = "";
                            }

                            var c_month = 'c_' + month;
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel[element.produit_code][c_month] = "";
                            }

                            var r_month = 'r_' + month;
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel[element.produit_code][r_month] = "";
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel[element.produit_code][trim] = "";;
                            }
                        })

                        vm.objectif_annuel[element.produit_code].coef = element.coef ? Number(element.coef) : "";;
                        vm.objectif_annuel[element.produit_code].objectif_coef_2 = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;
                        vm.objectif_annuel[element.produit_code].objectif_coef = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;


                    })

                    /**
                     * clear older year
                     */
                    _.each(vm.objectif_annuel_prec, element => {
                        // vm.objectif_annuel_prec[element.produit_code] = {};
                        _.each(vm.mois, month => {
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][month] = "";
                            }
                            var c_month = 'c_' + month;
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][c_month] = "";
                            }
                            var r_month = 'r_' + month;
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][r_month] = "";
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][trim] = "";
                            }
                        })

                        vm.objectif_annuel_prec[element.produit_code].coef = element.coef ? Number(element.coef) : "";;
                        vm.objectif_annuel_prec[element.produit_code].objectif_coef_2 = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;
                        vm.objectif_annuel_prec[element.produit_code].objectif_coef = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;



                    })
                    /**
                     * clear current year region
                     */
                    _.each(vm.objectif_annuel, element => {
                        //vm.objectif_annuel[element.produit_code] = {};
                        _.each(vm.mois, month => {
                            var month = month + '_coef';
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel[element.produit_code][month] = "";
                            }
                            var c_month = 'c_' + month + '_coef';
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel[element.produit_code][c_month] = "";
                            }
                            var r_month = 'r_' + month + '_coef';
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel[element.produit_code][r_month] = "";
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            var trim = trim + '_coef';
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel[element.produit_code][trim] = "";
                            }
                        })
                        vm.objectif_annuel[element.produit_code].coef = "";
                        vm.objectif_annuel[element.produit_code].objectif_coef_2 = "";
                        vm.objectif_annuel[element.produit_code].objectif_coef = "";

                    })
                    /**
                     * clear older year region
                     */
                    _.each(vm.objectif_annuel_prec, element => {
                        //vm.objectif_annuel_prec[element.produit_code] = {};
                        _.each(vm.mois, month => {
                            var month = month + '_coef';
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][month] = "";
                            }
                            var c_month = 'c_' + month + '_coef';
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][c_month] = "";
                            }
                            var r_month = 'r_' + month + '_coef';
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][r_month] = "";
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            var trim = trim + '_coef';
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][trim] = "";
                            }
                        })
                        vm.objectif_annuel_prec[element.produit_code].coef = "";
                        vm.objectif_annuel_prec[element.produit_code].objectif_coef_2 = "";
                        vm.objectif_annuel_prec[element.produit_code].objectif_coef = "";

                    })

                    /**
                     * current annee
                     */
                    _.each(response.data.obj_annuel, element => {
                        _.each(vm.mois, month => {
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel[element.produit_code][month] = element[month] ? Number(element[month]) : "";
                            }
                            var c_month = 'c_' + month;
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel[element.produit_code][c_month] = element[c_month] ? Number(element[c_month]) : "";
                            }
                            var r_month = 'r_' + month;
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel[element.produit_code][r_month] = element[r_month] ? Number(element[r_month]) : "";
                                //console.log()
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel[element.produit_code][trim] = element[trim] ? Number(element[trim]) : "";;
                            }
                        })

                        vm.objectif_annuel[element.produit_code].coef = element.coef ? Number(element.coef) : "";;
                        vm.objectif_annuel[element.produit_code].objectif_coef_2 = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;
                        vm.objectif_annuel[element.produit_code].objectif_coef = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;


                    })



                    /**
                     * annee precedant
                     */
                    _.each(response.data.obj_annuel_prec, element => {
                        //vm.objectif_annuel_prec[element.produit_code] = {};
                        _.each(vm.mois, month => {
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][month] = Number(element[month]);
                            }
                            var c_month = 'c_' + month;
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][c_month] = Number(element[c_month]);
                            }
                            var r_month = 'r_' + month;
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][r_month] = Number(element[r_month]);
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            if (element[trim] && element[trim] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][trim] = Number(element[trim]);
                            }
                        })

                    })

                    /**
                     * current year region
                     */
                    _.each(response.data.obj_annuel_region, element => {
                        _.each(vm.mois, month => {
                            var month_coef = month + '_coef';
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel[element.produit_code][month_coef] = element[month] ? Number(element[month]) : "";
                            }

                            var c_month = 'c_' + month + '_coef';
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel[element.produit_code][c_month] = element[c_month] ? Number(element[c_month]) : "";
                            }
                            var r_month = 'r_' + month + '_coef';
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel[element.produit_code][r_month] = element[r_month] ? Number(element[r_month]) : "";
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            var trim_coef = trim + '_coef';
                            vm.objectif_annuel[element.produit_code][trim_coef] = element[trim] ? Number(element[trim]) : 0;
                            //console.log('trims_coef => ', trim, element[trim], vm.objectif_annuel[element.produit_code][trim_coef])

                        })

                        vm.objectif_annuel[element.produit_code].coef = element.coef ? Number(element.coef) : "";;
                        vm.objectif_annuel[element.produit_code].objectif_coef_2 = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;


                    })

                    _.each(response.data.obj_annuel_region_prec, element => {
                        //vm.objectif_annuel_prec[element.produit_code] = {};
                        _.each(vm.mois, month => {
                            var month_coef = month + '_coef';
                            if (element[month] && element[month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][month_coef] = Number(element[month]);
                            }
                            var c_month = 'c_' + month + '_coef';
                            if (element[c_month] && element[c_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][c_month] = Number(element[c_month]);
                            }
                            var r_month = 'r_' + month + '_coef';
                            if (element[r_month] && element[r_month] != 0) {
                                vm.objectif_annuel_prec[element.produit_code][r_month] = Number(element[r_month]);
                            }
                        });

                        _.each(vm.all_trims, trim => {
                            var trim_coef = trim + '_coef';
                            vm.objectif_annuel_prec[element.produit_code][trim_coef] = element[trim] ? Number(element[trim]) : 0;
                        })
                        vm.objectif_annuel_prec[element.produit_code].coef = element.coef ? Number(element.coef) : "";;
                        vm.objectif_annuel_prec[element.produit_code].objectif_coef_2 = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;
                        vm.objectif_annuel_prec[element.produit_code].objectif_coef = element.objectif_coef_2 ? Number(element.objectif_coef_2) : "";;

                    })
                    vm.show_loader = false;
                    $scope.$apply();
                } else { }

            })
            .then(null, function (error) {
                vm.show_loader = false;
                swal('Error', 'erreur de chargement des données', 'error')
                console.log(error);
            });
    }



    vm.storeObjectifAnnuel = () => {
        vm.s_objectif_annuel = {};
        vm.show_loader = true;
        if (vm.year) {
            _.each(vm.objectif_annuel, element => {
                vm.s_objectif_annuel[element.produit_code] = {};

                vm.s_objectif_annuel[element.produit_code].produit_code = vm.objectif_annuel[element.produit_code].produit_code;
                vm.s_objectif_annuel[element.produit_code].prix_ht = vm.objectif_annuel[element.produit_code].prix_ht;;
                vm.s_objectif_annuel[element.produit_code].libelle = vm.objectif_annuel[element.produit_code].libelle;;
                vm.s_objectif_annuel[element.produit_code].gamme_code = vm.objectif_annuel[element.produit_code].gamme_code;;
                vm.s_objectif_annuel[element.produit_code].famille_code = vm.objectif_annuel[element.produit_code].famille_code;;
                vm.s_objectif_annuel[element.produit_code].sousfamille_code = vm.objectif_annuel[element.produit_code].sousfamille_code;;
                vm.s_objectif_annuel[element.produit_code].marque_code = vm.objectif_annuel[element.produit_code].marque_code;;
                vm.s_objectif_annuel[element.produit_code].objectif = vm.objectif_annuel[element.produit_code].objectif;;

                // total and chiffre total
                _.each(vm.mois, month => {
                    vm.s_objectif_annuel[element.produit_code][month] = vm.objectif_annuel[element.produit_code][month] ? Number(vm.objectif_annuel[element.produit_code][month]) : 0;
                })

                vm.s_objectif_annuel[element.produit_code].total = vm.objectif_annuel[element.produit_code].total ? Number(vm.objectif_annuel[element.produit_code].total) : 0;
                vm.s_objectif_annuel[element.produit_code].valeur = vm.objectif_annuel[element.produit_code].valeur ? vm.objectif_annuel[element.produit_code].valeur : 0;

                //trimestre
                vm.s_objectif_annuel[element.produit_code].trimestre_1 = vm.objectif_annuel[element.produit_code].trimestre_1 ? Number(vm.objectif_annuel[element.produit_code].trimestre_1) : 0;
                vm.s_objectif_annuel[element.produit_code].trimestre_2 = vm.objectif_annuel[element.produit_code].trimestre_2 ? Number(vm.objectif_annuel[element.produit_code].trimestre_2) : 0;
                vm.s_objectif_annuel[element.produit_code].trimestre_3 = vm.objectif_annuel[element.produit_code].trimestre_3 ? Number(vm.objectif_annuel[element.produit_code].trimestre_3) : 0;
                vm.s_objectif_annuel[element.produit_code].trimestre_4 = vm.objectif_annuel[element.produit_code].trimestre_4 ? Number(vm.objectif_annuel[element.produit_code].trimestre_4) : 0;

                //semsestre
                vm.s_objectif_annuel[element.produit_code].semestre_1 = vm.objectif_annuel[element.produit_code].semestre_1 ? Number(vm.objectif_annuel[element.produit_code].semestre_1) : 0;
                vm.s_objectif_annuel[element.produit_code].semestre_2 = vm.objectif_annuel[element.produit_code].semestre_2 ? Number(vm.objectif_annuel[element.produit_code].semestre_2) : 0;


                // coefficient 

                // total and chiffre total
                _.each(vm.mois, month => {
                    var month = month + '_coef';
                    vm.s_objectif_annuel[element.produit_code][month] = vm.objectif_annuel[element.produit_code][month] ? Number(vm.objectif_annuel[element.produit_code][month]) : 0;
                })
                vm.s_objectif_annuel[element.produit_code].coef = vm.objectif_annuel[element.produit_code].coef ? Number(vm.objectif_annuel[element.produit_code].coef) : 0;
                vm.s_objectif_annuel[element.produit_code].objectif_coef = vm.objectif_annuel[element.produit_code].objectif_coef_2 ? Number(vm.objectif_annuel[element.produit_code].objectif_coef_2) : 0;

                vm.s_objectif_annuel[element.produit_code].total_coef = vm.objectif_annuel[element.produit_code].total_coef ? Number(vm.objectif_annuel[element.produit_code].total_coef) : 0;
                vm.s_objectif_annuel[element.produit_code].valeur_coef = vm.objectif_annuel[element.produit_code].valeur_coef ? vm.objectif_annuel[element.produit_code].valeur_coef : 0;

                //trimestre
                vm.s_objectif_annuel[element.produit_code].trimestre_1_coef = vm.objectif_annuel[element.produit_code].trimestre_1_coef ? Number(vm.objectif_annuel[element.produit_code].trimestre_1_coef) : 0;
                vm.s_objectif_annuel[element.produit_code].trimestre_2_coef = vm.objectif_annuel[element.produit_code].trimestre_2_coef ? Number(vm.objectif_annuel[element.produit_code].trimestre_2_coef) : 0;
                vm.s_objectif_annuel[element.produit_code].trimestre_3_coef = vm.objectif_annuel[element.produit_code].trimestre_3_coef ? Number(vm.objectif_annuel[element.produit_code].trimestre_3_coef) : 0;
                vm.s_objectif_annuel[element.produit_code].trimestre_4_coef = vm.objectif_annuel[element.produit_code].trimestre_4_coef ? Number(vm.objectif_annuel[element.produit_code].trimestre_4_coef) : 0;

                //semsestre
                vm.s_objectif_annuel[element.produit_code].semestre_1_coef = vm.objectif_annuel[element.produit_code].semestre_1_coef ? Number(vm.objectif_annuel[element.produit_code].semestre_1_coef) : 0;
                vm.s_objectif_annuel[element.produit_code].semestre_2_coef = vm.objectif_annuel[element.produit_code].semestre_2_coef ? Number(vm.objectif_annuel[element.produit_code].semestre_2_coef) : 0;


            })

            var data = {
                year: vm.year,
                region_code: vm.region_code,
                produits: vm.s_objectif_annuel
            };

            WS.post('objectifs/storeObjectifAnnuel', data)
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Succès',
                            'objectif ajoutée avec Succès',
                            'success'
                        );

                    } else if (response.data.success == false) {
                        swal('Error', response.data.message, 'error');
                    }
                    vm.show_loader = false;
                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                    vm.show_loader = false;
                    $scope.$apply();
                });
        } else {
            swal(
                'Oups!',
                'Année obligatoire!',
                'warning'
            );
            vm.show_loader = false;
        }


    }


    vm.getcommercialName = () => {
        var currentTab = _.filter(vm.commercials, function (user) { return user.code == vm.user_code; });
        if (currentTab.length > 0) {
            vm.user_nom = currentTab[0].nom + ' ' + currentTab[0].prenom;
        } else {
            vm.user_nom = '';
        }

    }

    vm.getregionName = () => {
        var currentTab = _.filter(vm.regions, function (region) { return region.code == vm.region_code; });
        if (currentTab.length > 0) {
            vm.region_nom = currentTab[0].libelle;
        } else {
            vm.region_nom = '';
        }

    }
    vm.storeObjectifAnnuelUser = () => {
        vm.s_objectif_annuel = {};
        vm.show_loader = true;
        if (vm.year && vm.user_code && vm.region_code) {
            _.each(vm.objectif_annuel, element => {
                vm.s_objectif_annuel[element.produit_code] = {};

                vm.s_objectif_annuel[element.produit_code].produit_code = vm.objectif_annuel[element.produit_code].produit_code;
                vm.s_objectif_annuel[element.produit_code].prix_ht = vm.objectif_annuel[element.produit_code].prix_ht;;
                vm.s_objectif_annuel[element.produit_code].libelle = vm.objectif_annuel[element.produit_code].libelle;;
                vm.s_objectif_annuel[element.produit_code].gamme_code = vm.objectif_annuel[element.produit_code].gamme_code;;
                vm.s_objectif_annuel[element.produit_code].famille_code = vm.objectif_annuel[element.produit_code].famille_code;;
                vm.s_objectif_annuel[element.produit_code].sousfamille_code = vm.objectif_annuel[element.produit_code].sousfamille_code;;
                vm.s_objectif_annuel[element.produit_code].marque_code = vm.objectif_annuel[element.produit_code].marque_code;;
                vm.s_objectif_annuel[element.produit_code].objectif = vm.objectif_annuel[element.produit_code].objectif;;


                // coefficient 

                // total and chiffre total
                _.each(vm.mois, month => {
                    var month = month + '_coef';
                    vm.s_objectif_annuel[element.produit_code][month] = vm.objectif_annuel[element.produit_code][month] ? Number(vm.objectif_annuel[element.produit_code][month]) : 0;
                })
                vm.s_objectif_annuel[element.produit_code].coef = vm.objectif_annuel[element.produit_code].coef ? Number(vm.objectif_annuel[element.produit_code].coef) : 0;
                vm.s_objectif_annuel[element.produit_code].objectif_coef = vm.objectif_annuel[element.produit_code].objectif_coef_2 ? Number(vm.objectif_annuel[element.produit_code].objectif_coef_2) : 0;


                vm.s_objectif_annuel[element.produit_code].cof = vm.objectif_annuel[element.produit_code].cof ? Number(vm.objectif_annuel[element.produit_code].cof) : 0;
                vm.s_objectif_annuel[element.produit_code].cle_cof = vm.objectif_annuel[element.produit_code].cle_cof ? Number(vm.objectif_annuel[element.produit_code].cle_cof) : 0;

                vm.s_objectif_annuel[element.produit_code].total_coef = vm.objectif_annuel[element.produit_code].total_coef ? Number(vm.objectif_annuel[element.produit_code].total_coef) : 0;
                vm.s_objectif_annuel[element.produit_code].valeur_coef = vm.objectif_annuel[element.produit_code].valeur_coef ? vm.objectif_annuel[element.produit_code].valeur_coef : 0;

                //trimestre
                vm.s_objectif_annuel[element.produit_code].trimestre_1_coef = vm.objectif_annuel[element.produit_code].trimestre_1_coef ? Number(vm.objectif_annuel[element.produit_code].trimestre_1_coef) : 0;
                vm.s_objectif_annuel[element.produit_code].trimestre_2_coef = vm.objectif_annuel[element.produit_code].trimestre_2_coef ? Number(vm.objectif_annuel[element.produit_code].trimestre_2_coef) : 0;
                vm.s_objectif_annuel[element.produit_code].trimestre_3_coef = vm.objectif_annuel[element.produit_code].trimestre_3_coef ? Number(vm.objectif_annuel[element.produit_code].trimestre_3_coef) : 0;
                vm.s_objectif_annuel[element.produit_code].trimestre_4_coef = vm.objectif_annuel[element.produit_code].trimestre_4_coef ? Number(vm.objectif_annuel[element.produit_code].trimestre_4_coef) : 0;

                //semsestre
                vm.s_objectif_annuel[element.produit_code].semestre_1_coef = vm.objectif_annuel[element.produit_code].semestre_1_coef ? Number(vm.objectif_annuel[element.produit_code].semestre_1_coef) : 0;
                vm.s_objectif_annuel[element.produit_code].semestre_2_coef = vm.objectif_annuel[element.produit_code].semestre_2_coef ? Number(vm.objectif_annuel[element.produit_code].semestre_2_coef) : 0;


            })

            var data = {
                year: vm.year,
                region_code: vm.region_code,
                user_code: vm.user_code,
                user_nom: vm.user_nom,
                produits: vm.s_objectif_annuel
            };

            WS.post('objectifs/storeObjectifAnnuelUser', data)
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Succès',
                            'objectif ajoutée avec Succès',
                            'success'
                        );

                    } else if (response.data.success == false) {
                        swal('Error', response.data.message, 'error');
                    }
                    vm.show_loader = false;
                    $scope.$apply();
                })
                .then(null, function (error) {
                    console.log(error);
                    vm.show_loader = false;
                    $scope.$apply();
                });
        } else {
            swal(
                'Oups!',
                'Veuillez bien remplir les champs (region, user, annee)!',
                'warning'
            )
            vm.show_loader = false;
        }


    }


    vm.get = function () {
        WS.get('objectifs/all', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.objectifs = response.data;
                    $scope.$apply();
                    $('.tableobj').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.initget = function () {
        WS.get('objectifs/all', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.objectifs = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getFilter = function (filter) {
        WS.get('objectifs/all/' + filter.date_debut + '/' + filter.date_fin, vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.objectifs = response.data;
                    $scope.$apply();
                    $('.tableobj').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getcommercial = function () {
        WS.getScache('users/selectComercial')
            .then(function (response) {
                if (response.status == 200) {
                    vm.commercials = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getMarques = function () {
        WS.get('marques')
            .then(function (response) {
                if (response.status == 200) {
                    vm.marques = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getgammes = function () {
        WS.get('gammes', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.gammes = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getCommercialByEquipement = function (code, liste, attribute) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code) {
                return liste[i];
            }

        }
    }

    vm.ajouter = function (objectif) {
        WS.post('objectifs', objectif)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'objectif ajoutée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.update = function (objectif) {
        console.log(objectif);
        WS.put('objectifs/all/' + objectif.id, objectif)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'objectif modifiée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.delete = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette objectif!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('objectifs/' + id).then(
                function () {
                    swal(
                        'Supprimée!',
                        'objectif Supprimée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette objectif',
                        'warning'
                    );
                }
            );
        });
    }

    /**
     * All Graph for objectif
     */

    vm.objectifGraph = function () {
        WS.getScache('objectifs/getObjGraph').then(function (response) {
            console.log(response.data);
            vm.objectifsgraph = response.data;
            $scope.$apply();
            $('.tablegetObjGraph').DataTable({
                "language": DatatableLang
            });
        })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.initobjectifGraph = function () {
        WS.getScache('objectifs/getObjGraph').then(function (response) {
            vm.objectifsgraph = response.data;
            $scope.$apply();
        })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.objectifGraphFilter = function (filter) {
        WS.getScache('objectifs/getObjGraph/' + filter.date_debut + '/' + filter.date_fin).then(function (response) {
            vm.objectifsgraph = response.data;
            $scope.$apply();
        })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.objectifGraphComm = function () {
        WS.get('objectifs/getObjGraphComm').then(function (response) {

            if (response.status == 200) {
                vm.objectifsgraphcomm = response.data;
                $scope.$apply();
                $('.tablegetObjGraph').DataTable({
                    "language": DatatableLang
                });
            } else { }
        })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.initobjectifGraphComm = function () {
        WS.get('objectifs/getObjGraphComm').then(function (response) {

            if (response.status == 200) {
                vm.objectifsgraphcomm = response.data;
                $scope.$apply();
            } else { }
        })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.objectifGraphCommFilter = function (filter) {
        WS.get('objectifs/getObjGraphComm/' + filter.date_debut + '/' + filter.date_fin).then(function (response) {

            if (response.status == 200) {
                vm.objectifsgraphcomm = response.data;
                $scope.$apply();
            } else { }
        })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.listObjectifsVisites = function (filter) {
        WS.get('objectifs/listObjectifsVisites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.listobjvisites = response.data;
                    $scope.$apply();
                    $('.tableobjvisite').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.listObjectifsJournalier = function (filter) {
        WS.get('objectifs/listObjectifsJournalier')
            .then(function (response) {
                if (response.status == 200) {
                    vm.listobjvisites = response.data;
                    $scope.$apply();
                    $('.tableobjvisite').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.saveObjectifsJournalier = function () {
        WS.post('objectifs/saveObjectifsJournalier', vm.addobjvisite)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'objectif ajoutée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.saveObjectifsVisites = function () {
        WS.post('objectifs/saveObjectifsVisites', vm.addobjvisite)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'objectif ajoutée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.updateObjectifsVisites = function () {
        //console.log(objectif);
        WS.put('objectifs/updateObjectifsVisites', vm.modifObjectif)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'objectif modifiée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.deleteObjectifsVisites = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette objectif!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('objectifs/deleteObjectifsVisites/' + id).then(
                function () {
                    swal(
                        'Supprimée!',
                        'objectif Supprimée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette objectif',
                        'warning'
                    );
                }
            );
        });
    }

    /**
     * 
     */

    vm.initYear = () => {
        $(".picker_year").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.year = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });
    }
    vm.initYearRegion = () => {
        $(".picker_year_region").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.year = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });
    }
    vm.initDatepicker = function () {
        $(".datepicker-dashb").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.multi_objs.month = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });

        $(".datepicker-dashb-search").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.find_by_month = date;
                $scope.$apply();
                //console.log(vm.month);
            }
        });

        // $(".week-picker").weekpicker({
        //     beforeShowDay: $.datepicker.noWeekends,
        //     autoClose: true,
        //     onSelect: function (date) {
        //         vm.multi_objs.week = date;
        //         $scope.$apply();
        //         //console.log(vm.month);
        //     }
        // });
        // $(document).ready(function () {
        //     $('.week-picker').weekpicker();
        // })


    };

    vm.getFamilles = function () {
        WS.get('familles')
            .then(function (response) {
                vm.familles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSousfamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                vm.sousfamilles = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getGroupes = function () {
        WS.get('groupes/select')
            .then(function (response) {
                vm.groupes = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.selectProduits = function () {
        vm.show_loader = true;
        WS.get('produits/selectProduit')
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    //vm.getObjectifAnnuel();
                    _.each(vm.produits, produit => {



                        $scope.$watch(`vm.objectif_annuel["${produit.code}"]`, function (newModel, oldModel) {
                            if (typeof newModel === 'object') {
                                var total = 0;
                                var valeur = 0;
                                //console.log('newModel', newModel)

                                // total and chiffre total
                                _.each(vm.mois, month => {
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                if (total && total > 0) {
                                    vm.objectif_annuel[newModel.produit_code].total = total ? total.toFixed(0) : '';
                                }
                                if (valeur && valeur > 0) {
                                    vm.objectif_annuel[newModel.produit_code].valeur = valeur.toFixed(3);
                                }


                                //TRIMESTRE_1
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.trimestre_1, month => {
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].trimestre_1 = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].trimestre_1_valeur = valeur.toFixed(3);


                                //TRIMESTRE_2
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.trimestre_2, month => {
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].trimestre_2 = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].trimestre_2_valeur = valeur.toFixed(3);

                                //TRIMESTRE_3
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.trimestre_3, month => {
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].trimestre_3 = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].trimestre_3_valeur = valeur.toFixed(3);

                                //TRIMESTRE_4
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.trimestre_4, month => {
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].trimestre_4 = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].trimestre_4_valeur = valeur.toFixed(3);

                                //SEMESTRE_1
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.semestre_1, month => {
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].semestre_1 = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].semestre_1_valeur = valeur.toFixed(3);

                                //SEMESTRE_2
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.semestre_2, month => {
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].semestre_2 = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].semestre_2_valeur = valeur.toFixed(3);



                                /**
                                 * coefficient et recalcule des objectifs par mois
                                 */
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.mois, month => {
                                    var month = month + '_coef';
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                if (total && total > 0) {
                                    vm.objectif_annuel[newModel.produit_code].total_coef = total ? total.toFixed(0) : '';
                                }
                                if (valeur && valeur > 0) {
                                    vm.objectif_annuel[newModel.produit_code].valeur_coef = valeur.toFixed(3);
                                }

                                //TRIMESTRE_1 COEFF
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.trimestre_1, month => {
                                    var month = month + '_coef';
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].trimestre_1_coef = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].trimestre_1_valeur_coef = valeur.toFixed(3);


                                //TRIMESTRE_2 COEFF
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.trimestre_2, month => {
                                    var month = month + '_coef';
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].trimestre_2_coef = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].trimestre_2_valeur_coef = valeur.toFixed(3);

                                //TRIMESTRE_3 COEFF
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.trimestre_3, month => {
                                    var month = month + '_coef';
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].trimestre_3_coef = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].trimestre_3_valeur_coef = valeur.toFixed(3);

                                //TRIMESTRE_4 COEFF
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.trimestre_4, month => {
                                    var month = month + '_coef';
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].trimestre_4_coef = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].trimestre_4_valeur_coef = valeur.toFixed(3);

                                //SEMESTRE_1 COEFF
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.semestre_1, month => {
                                    var month = month + '_coef';
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].semestre_1_coef = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].semestre_1_valeur_coef = valeur.toFixed(3);

                                //SEMESTRE_2 COEFF
                                var total = 0;
                                var valeur = 0;
                                _.each(vm.semestre_2, month => {
                                    var month = month + '_coef';
                                    total += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) : 0;
                                    valeur += (vm.objectif_annuel[newModel.produit_code][month]) ? Number(vm.objectif_annuel[newModel.produit_code][month]) * newModel.prix_ht : 0;
                                })
                                vm.objectif_annuel[newModel.produit_code].semestre_2_coef = total.toFixed(0);
                                vm.objectif_annuel[newModel.produit_code].semestre_2_valeur_coef = valeur.toFixed(3);

                                //vm.recalculateSumObjs(newModel.produit_code);

                            }
                        }, true);


                        // $scope.$watch(`vm.objectif_annuel["${produit.code}"].coef`, function (newModel, oldModel) {
                        //     if (newModel) {
                        //         vm.recalculateObjsCoef(produit.code);
                        //     }

                        // }, true);
                    })

                    //console.log('vm.objectif_annuel', vm.objectif_annuel);

                    vm.show_loader = false;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                vm.show_loader = false;
                console.log(error);
            });
    };


    vm.recalculateObjsCoefUser = (produit_code) => {
        //vm.objectif_annuel[produit_code].objectif_coef = (Number(vm.objectif_annuel[produit_code].objectif) * (Number(vm.objectif_annuel[produit_code].coef) / 100)).toFixed(0);
        var total = 0;
        _.each(vm.mois, (el) => {
            var mois_coef = el + '_coef';


            vm.objectif_annuel[produit_code][el] = Number(vm.objectif_annuel[produit_code][el]) ? Number(vm.objectif_annuel[produit_code][el]) : 0;
            vm.objectif_annuel[produit_code].cle_cof = Number(vm.objectif_annuel[produit_code].cle_cof) ? Number(vm.objectif_annuel[produit_code].cle_cof) : 1;
            vm.objectif_annuel[produit_code].cof = Number(vm.objectif_annuel[produit_code].cof) ? Number(vm.objectif_annuel[produit_code].cof) : 1;


            vm.objectif_annuel[produit_code][mois_coef] = Number(vm.objectif_annuel[produit_code][el]) * (Number(vm.objectif_annuel[produit_code].cle_cof) / 100) * (Number(vm.objectif_annuel[produit_code].cof) / 100);
            total += vm.objectif_annuel[produit_code][mois_coef];
            vm.objectif_annuel[produit_code][mois_coef] = vm.objectif_annuel[produit_code][mois_coef] ? vm.objectif_annuel[produit_code][mois_coef].toFixed(0) : 0;
            console.log(
                produit_code,
                vm.objectif_annuel[produit_code][mois_coef], '=',
                vm.objectif_annuel[produit_code][el], '*', vm.objectif_annuel[produit_code].coef, '/100'
            );
        })

        vm.objectif_annuel[produit_code].total_coef = total ? total.toFixed(0) : 0;
        vm.objectif_annuel[produit_code].valeur_coef = total ? (total * vm.objectif_annuel[produit_code].prix_ht).toFixed(3) : 0;

        //objectif
        vm.objectif_annuel[produit_code].objectif_coef_2 = Number(vm.objectif_annuel[produit_code].objectif_coef) * (Number(vm.objectif_annuel[produit_code].coef) / 100);
        vm.objectif_annuel[produit_code].objectif_coef_2 = vm.objectif_annuel[produit_code].objectif_coef_2.toFixed(0);
        //TRIMESTRE_1
        var total = 0;
        var valeur = 0;
        _.each(vm.trimestre_1, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].trimestre_1_coef = total;
        vm.objectif_annuel[produit_code].trimestre_1_coef_valeur = valeur.toFixed(3);

        //TRIMESTRE_2
        var total = 0;
        var valeur = 0;
        _.each(vm.trimestre_2, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].trimestre_2_coef = total;
        vm.objectif_annuel[produit_code].trimestre_2_coef_valeur = valeur.toFixed(3);

        //TRIMESTRE_3
        var total = 0;
        var valeur = 0;
        _.each(vm.trimestre_3, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].trimestre_3_coef = total;
        vm.objectif_annuel[produit_code].trimestre_3_coef_valeur = valeur.toFixed(3);

        //TRIMESTRE_4
        var total = 0;
        var valeur = 0;
        _.each(vm.trimestre_4, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].trimestre_4_coef = total;
        vm.objectif_annuel[produit_code].trimestre_4_coef_valeur = valeur.toFixed(3);

        //SEMESTRE_1
        var total = 0;
        var valeur = 0;
        _.each(vm.semestre_1, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].semestre_1_coef = total;
        vm.objectif_annuel[produit_code].semestre_1_coef_valeur = valeur.toFixed(3);

        //SEMESTRE_2
        var total = 0;
        var valeur = 0;
        _.each(vm.semestre_2, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].semestre_2_coef = total;
        vm.objectif_annuel[produit_code].semestre_2_coef_valeur = valeur.toFixed(3);
    }


    vm.recalculateObjsCoef = (produit_code) => {
        if (vm.objectif_annuel[produit_code].coef) {
            //vm.objectif_annuel[produit_code].objectif_coef = (Number(vm.objectif_annuel[produit_code].objectif) * (Number(vm.objectif_annuel[produit_code].coef) / 100)).toFixed(0);
            var total = 0;
            _.each(vm.mois, (el) => {
                var mois_coef = el + '_coef';
                vm.objectif_annuel[produit_code][mois_coef] = (Number(vm.objectif_annuel[produit_code][el]) * (Number(vm.objectif_annuel[produit_code].coef) / 100));
                total += vm.objectif_annuel[produit_code][mois_coef];
                vm.objectif_annuel[produit_code][mois_coef] = vm.objectif_annuel[produit_code][mois_coef] ? vm.objectif_annuel[produit_code][mois_coef].toFixed(0) : 0;
                console.log(
                    produit_code,
                    vm.objectif_annuel[produit_code][mois_coef], '=',
                    vm.objectif_annuel[produit_code][el], '*', vm.objectif_annuel[produit_code].coef, '/100'
                );
            })

            vm.objectif_annuel[produit_code].total_coef = total ? total.toFixed(0) : 0;
            vm.objectif_annuel[produit_code].valeur_coef = total ? (total * vm.objectif_annuel[produit_code].prix_ht).toFixed(3) : 0;

            //objectif
            vm.objectif_annuel[produit_code].objectif_coef_2 = Number(vm.objectif_annuel[produit_code].objectif) * (Number(vm.objectif_annuel[produit_code].coef) / 100);
            vm.objectif_annuel[produit_code].objectif_coef_2 = vm.objectif_annuel[produit_code].objectif_coef_2.toFixed(0);
            //TRIMESTRE_1
            var total = 0;
            var valeur = 0;
            _.each(vm.trimestre_1, month => {
                var month = month + '_coef';
                total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
                valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
            })
            vm.objectif_annuel[produit_code].trimestre_1_coef = total;
            vm.objectif_annuel[produit_code].trimestre_1_coef_valeur = valeur.toFixed(3);

            //TRIMESTRE_2
            var total = 0;
            var valeur = 0;
            _.each(vm.trimestre_2, month => {
                var month = month + '_coef';
                total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
                valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
            })
            vm.objectif_annuel[produit_code].trimestre_2_coef = total;
            vm.objectif_annuel[produit_code].trimestre_2_coef_valeur = valeur.toFixed(3);

            //TRIMESTRE_3
            var total = 0;
            var valeur = 0;
            _.each(vm.trimestre_3, month => {
                var month = month + '_coef';
                total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
                valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
            })
            vm.objectif_annuel[produit_code].trimestre_3_coef = total;
            vm.objectif_annuel[produit_code].trimestre_3_coef_valeur = valeur.toFixed(3);

            //TRIMESTRE_4
            var total = 0;
            var valeur = 0;
            _.each(vm.trimestre_4, month => {
                var month = month + '_coef';
                total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
                valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
            })
            vm.objectif_annuel[produit_code].trimestre_4_coef = total;
            vm.objectif_annuel[produit_code].trimestre_4_coef_valeur = valeur.toFixed(3);

            //SEMESTRE_1
            var total = 0;
            var valeur = 0;
            _.each(vm.semestre_1, month => {
                var month = month + '_coef';
                total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
                valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
            })
            vm.objectif_annuel[produit_code].semestre_1_coef = total;
            vm.objectif_annuel[produit_code].semestre_1_coef_valeur = valeur.toFixed(3);

            //SEMESTRE_2
            var total = 0;
            var valeur = 0;
            _.each(vm.semestre_2, month => {
                var month = month + '_coef';
                total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
                valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
            })
            vm.objectif_annuel[produit_code].semestre_2_coef = total;
            vm.objectif_annuel[produit_code].semestre_2_coef_valeur = valeur.toFixed(3);


        }
    }

    vm.recalculateSumObjs = (produit_code) => {
        console.log('recalculateSumObjs', '=>', produit_code)
        var total = 0;
        var valeur = 0;

        // total and chiffre total
        _.each(vm.mois, month => {
            var mois_coef = month + '_coef';
            total += (vm.objectif_annuel[produit_code][mois_coef]) ? Number(vm.objectif_annuel[produit_code][mois_coef]) : 0;
            valeur += (vm.objectif_annuel[produit_code][mois_coef]) ? Number(vm.objectif_annuel[produit_code][mois_coef]) * vm.objectif_annuel[produit_code].prix_ht : 0;

            console.log(vm.objectif_annuel[produit_code][mois_coef]);
            vm.objectif_annuel[produit_code][mois_coef] = vm.objectif_annuel[produit_code][mois_coef] ? Number(vm.objectif_annuel[produit_code][mois_coef]).toFixed(0) : 0;
        })
        if (total && total > 0) {
            vm.objectif_annuel[produit_code].total_coef = total ? total.toFixed(0) : '';
        }
        if (valeur && valeur > 0) {
            vm.objectif_annuel[produit_code].valeur_coef = valeur.toFixed(3);
        }


        //TRIMESTRE_1
        var total = 0;
        var valeur = 0;
        _.each(vm.trimestre_1, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].trimestre_1_coef = total.toFixed(0);
        vm.objectif_annuel[produit_code].trimestre_1_valeur_coef = valeur.toFixed(3);

        //TRIMESTRE_2
        var total = 0;
        var valeur = 0;
        _.each(vm.trimestre_2, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].trimestre_2_coef = total.toFixed(0);
        vm.objectif_annuel[produit_code].trimestre_2_valeur_coef = valeur.toFixed(3);

        //TRIMESTRE_3
        var total = 0;
        var valeur = 0;
        _.each(vm.trimestre_3, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].trimestre_3_coef = total.toFixed(0);
        vm.objectif_annuel[produit_code].trimestre_3_valeur_coef = valeur.toFixed(3);

        //TRIMESTRE_4
        var total = 0;
        var valeur = 0;
        _.each(vm.trimestre_4, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].trimestre_4_coef = total.toFixed(0);
        vm.objectif_annuel[produit_code].trimestre_4_valeur_coef = valeur.toFixed(3);

        //SEMESTRE_1
        var total = 0;
        var valeur = 0;
        _.each(vm.semestre_1, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].semestre_1_coef = total.toFixed(0);
        vm.objectif_annuel[produit_code].semestre_1_valeur_coef = valeur.toFixed(3);

        //SEMESTRE_2
        var total = 0;
        var valeur = 0;
        _.each(vm.semestre_2, month => {
            var month = month + '_coef';
            total += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) : 0;
            valeur += (vm.objectif_annuel[produit_code][month]) ? Number(vm.objectif_annuel[produit_code][month]) * vm.objectif_annuel[produit_code].prix_ht : 0;
        })
        vm.objectif_annuel[produit_code].semestre_2_coef = total.toFixed(0);
        vm.objectif_annuel[produit_code].semestre_2_valeur_coef = valeur.toFixed(3);
    }


    vm.selectClientsWithCode = function () {
        WS.get('clients/selectClientsWithCode')
            .then(function (response) {
                if (response.status == 200) {
                    vm.clients = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getListOfOblectifs2 = function () {
        WS.getData('objectifs/getListOfOblectifs2', { obj_month: vm.find_by_month })
            .then(function (response) {
                if (response.status == 200) {
                    vm.list_objectifs_2 = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.gererLesObjectifs = function () {
        swal({
            title: 'Enregistrer',
            text: "Voulez vous vraiment enregistrer les objectifs ? ",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            console.log(vm.multi_objs);

            WS.post('objectifs/storeMultipleObjectifs', vm.multi_objs).then(function (response) {
                if (response.data.success == true) {
                    swal({
                        title: 'Succé',
                        text: "Les objectifs sont enregistrés avec succès",
                        type: 'success',
                    });
                    //$window.location.reload();
                    vm.multi_objs.month = moment().format("YYYY-MM");
                    vm.multi_objs.week = '';
                    _.each(vm.commercials, (element, index) => {
                        vm.multi_objs.users[element.code] = {};
                    })
                    $scope.$apply();
                } else { }
            })
                .then(null, function (error) {
                    console.log(error);
                });
        });
    };



    vm.updateObjectifs2 = function () {
        console.log(vm.update_objectif2);
        WS.post('objectifs/updateObjectifs2', vm.update_objectif2)
            .then(function (response) {
                if (response.status == 200) {
                    _.each(vm.list_objectifs_2, (element, index) => {
                        if (element.id == response.data.id) {
                            vm.list_objectifs_2[index] = response.data;
                        }
                    })
                    swal('succé', 'Objectif modifié avec succé', 'success');
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });;
    };



    vm.getRegions = function () {
        WS.get('regions')
            .then(function (response) {
                if (response.status == 200) {
                    vm.regions = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.trustHtml = function (html) {
        return $sce.trustAsHtml(html);
    };

    vm.dates = [];

    vm.filter_objs = {};
    vm.getObjectifsJournalier = function () {
        vm.dates = [];
        vm.dates = getDatesInRange(vm.filter_objs.date_debut, vm.filter_objs.date_fin);
        WS.getData('objectifs/getObjectifsJournalier', vm.filter_objs)
            .then(function (response) {
                if (response.status == 200) {
                    vm.list_objs = response.data.objectifs;
                    vm.list_ventes = response.data.ventes;


                    vm.ths = '<th class="text-center" >Date</th>';
                    _.each(vm.list_objs, element => {
                        vm.ths += `<th class="text-center brd-2">Vente </th>
                        <th class="text-center">Quantite </th>
                        <th class="text-center">Colisage </th>
                        <th class="text-center">Objectif </th>`;
                    });

                    //tbody
                    vm.tbodys = '';
                    _.each(vm.dates, date => {
                        vm.tbodys += `<tr><td class="text-center">${date} </td>`;

                        _.each(vm.list_objs, element => {

                            if (vm.list_ventes[date + '_' + element.marque_code + '_' + element.user_code]) {
                                var taux_obj = vm.list_ventes[date + '_' + element.marque_code + '_' + element.user_code].quantite * 100 /
                                    element.obj_visite;
                                taux_obj = taux_obj.toFixed(2);
                                vm.tbodys += `
                            <td class="text-center brd-2">${vm.list_ventes[date + '_' + element.marque_code + '_' + element.user_code].vente} </td>
                            <td class="text-center">${vm.list_ventes[date + '_' + element.marque_code + '_' + element.user_code].quantite}  </td>
                            <td class="text-center">${vm.list_ventes[date + '_' + element.marque_code + '_' + element.user_code].colisage}  </td>
                            <td class="text-center">${taux_obj} % </td>`;
                            }else{
                                vm.tbodys += `
                                <td class="text-center brd-2"> </td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>`; 
                            }

                        });

                        vm.tbodys += '</tr>';

                    })

                    vm.ths = vm.trustHtml(vm.ths);
                    vm.tbodys = vm.trustHtml(vm.tbodys);

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    function getDatesInRange(startDate, endDate) {
        console.log(startDate, endDate)
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        console.log(startDate, endDate)

        var date = new Date(startDate.getTime());
        const dates = [];

        while (date <= endDate) {
            var date_str = moment(date).format('YYYY-MM-DD');

            dates.push(date_str);
            date.setDate(date.getDate() + 1);
        }

        console.log(dates)
        return dates;
    }




}
export default {
    name: 'objectifController',
    fn: objectifController
};